import { MultiLanguageString } from './types.types';

export enum IRawMaterialLogAction {
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  DENIED = 'DENIED',
  REQUESTED = 'REQUESTED'
}

export interface IRawMaterialLog {
  action: IRawMaterialLogAction;
  createdTimestamp: string;
  id: string;
  rawMaterialId: string;
  userId: string;
}

export interface HsCode {
  code: string;
  description: string;
}

export enum IEudrMaterialType {
  CATTLE = 'CATTLE',
  COCOA = 'COCOA',
  COFFEE = 'COFFEE',
  NONE = 'NONE',
  OILPALM = 'OILPALM',
  RUBBER = 'RUBBER',
  SOYA = 'SOYA',
  WOOD = 'WOOD'
}

export interface IRawMaterial {
  createdTimestamp: string;
  eudrMaterialType?: IEudrMaterialType;
  hsCodes?: HsCode[];
  id: string;
  latestLog: IRawMaterialLog;
  latestLogAction: IRawMaterialLogAction;
  logs: IRawMaterialLog[];
  title: string;
}

export type IRawMaterialMultiLang<T = MultiLanguageString> = {
  createdTimestamp: string;
  eudrMaterialType?: IEudrMaterialType;
  hsCodes?: HsCode[];
  id?: string;
  latestLog: IRawMaterialLog;
  latestLogAction: IRawMaterialLogAction;
  logs: IRawMaterialLog[];
  title: T;
};

export interface IRawMaterialsResult {
  rawMaterials: {
    edges: {
      node: IRawMaterialMultiLang;
    }[];
  };
}
