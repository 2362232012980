export default [
  {
    short: 'en',
    full: 'English'
  },
  {
    short: 'de',
    full: 'German'
  },
  {
    short: 'fr',
    full: 'French'
  },
  {
    short: 'nl',
    full: 'Dutch'
  },
  {
    short: 'es',
    full: 'Spanish'
  },
  {
    short: 'tr',
    full: 'Turkish'
  }
];
