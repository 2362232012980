import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_RAW_MATERIAL, UPDATE_RAW_MATERIAL } from 'graphql/mutations';
import { GET_RAW_MATERIALS } from 'graphql/queries';
import {
  IRawMaterialLogAction,
  IRawMaterialMultiLang,
  IRawMaterialsResult
} from 'types/rawMaterials.types';

interface IRawMaterialHook {
  activeRawMaterialId?: string;
  activeRawMaterialStatuses?: IRawMaterialLogAction[];
}

const useRawMaterials = ({
  activeRawMaterialId,
  activeRawMaterialStatuses
}: IRawMaterialHook) => {
  const navigate = useNavigate();
  const {
    data: { rawMaterials: { edges: rawMaterialEdges = [] } = {} } = {},
    error,
    loading,
    refetch
  } = useQuery<IRawMaterialsResult>(GET_RAW_MATERIALS, {
    fetchPolicy: activeRawMaterialId ? 'cache-first' : 'cache-and-network'
  });

  const rawMaterials = useMemo(() => {
    return rawMaterialEdges.map(({ node }) => node);
  }, [rawMaterialEdges]);

  const [createRawMaterial] = useMutation(CREATE_RAW_MATERIAL, {
    onCompleted: () => {
      toast.success('Raw material added successfully');
      navigate(`/raw-materials`);
      refetch();
    },
    onError: () => {
      toast.error('Something went wrong while adding raw material');
    }
  });

  const [updateRawMaterial] = useMutation(UPDATE_RAW_MATERIAL, {
    onCompleted: data => {
      const status = data.updateRawMaterial.rawMaterial.latestLogAction;

      if (status === 'APPROVED') {
        navigate(`/raw-materials`);
      } else {
        navigate(`/raw-materials/requests`);
      }

      toast.success('Raw material updated successfully');
    },
    onError: () => {
      toast.error('Something went wrong while updating raw material');
    }
  });

  const handleUpdateRawMaterial = async (values: IRawMaterialMultiLang) => {
    await updateRawMaterial({
      variables: {
        id: activeRawMaterialId,
        input: {
          title: values.title,
          status: values.latestLogAction,
          eudrMaterialType:
            values.eudrMaterialType && values.eudrMaterialType !== 'NONE'
              ? values.eudrMaterialType
              : null,
          hsCodes: values.hsCodes?.length ? values.hsCodes : null
        }
      }
    });
  };

  const handleCreateRawMaterial = async (values: IRawMaterialMultiLang) => {
    await createRawMaterial({
      variables: {
        input: {
          title: values.title,
          eudrMaterialType:
            values.eudrMaterialType && values.eudrMaterialType !== 'NONE'
              ? values.eudrMaterialType
              : null,
          hsCodes: values.hsCodes?.length ? values.hsCodes : null
        }
      }
    });
  };

  const activeRawMaterial = useMemo(() => {
    return rawMaterials.find(({ id }) => id === activeRawMaterialId);
  }, [activeRawMaterialId, rawMaterials]);

  const handleUpdateRawMaterialStatus = async (
    status: IRawMaterialLogAction
  ) => {
    if (activeRawMaterialId && !!activeRawMaterial) {
      await handleUpdateRawMaterial({
        ...activeRawMaterial,
        latestLogAction: status
      });
    } else {
      toast.error(
        'Something went wrong while updating the certification status'
      );
    }
  };

  const handleRawMaterialClick = (rawMaterialId: string) => {
    navigate(`/raw-materials/${rawMaterialId}`);
  };

  return {
    rawMaterials: rawMaterials.filter(
      ({ latestLogAction }) =>
        !activeRawMaterialStatuses ||
        (latestLogAction && activeRawMaterialStatuses.includes(latestLogAction))
    ),
    error: error,
    loading: loading,
    activeRawMaterial: activeRawMaterial,
    handleRawMaterialClick,
    handleUpdateRawMaterial,
    handleUpdateRawMaterialStatus,
    handleCreateRawMaterial
  };
};

export default useRawMaterials;
