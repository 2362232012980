import React, { FC, Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, MenuItem, Select, TableCell } from '@mui/material';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  IRawMaterialLogAction,
  IRawMaterialMultiLang
} from 'types/rawMaterials.types';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault
} from 'use-query-params';
import { getEnglishValue } from 'utils/translations';
import { SearchField } from 'components/Companies/CompaniesPage/styles';
import Table, { HeadCell } from 'components/Table/Table';
import useRawMaterials from './hooks/useRawMaterials';

const headCells: HeadCell<IRawMaterialMultiLang<string | undefined>>[] = [
  {
    id: 'title',
    label: 'Raw material'
  },
  {
    id: 'latestLogAction',
    label: 'Status'
  }
];

const StyledSelect = styled(Select)`
  margin-left: ${({ theme }) => theme.spacing(1)};

  .MuiSelect-select {
    color: black;
    padding: ${({ theme }) => theme.spacing(1, 1.5)};
  }
`;

interface IManageRawMaterialsPageProps {
  activeRawMaterialStatuses: IRawMaterialLogAction[];
}

const ManageRawMaterialsPage: FC<IManageRawMaterialsPageProps> = ({
  activeRawMaterialStatuses
}) => {
  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    pageSize: withDefault(NumberParam, 20),
    page: withDefault(NumberParam, 0),
    status: withDefault(
      StringParam,
      activeRawMaterialStatuses.includes(IRawMaterialLogAction.REQUESTED)
        ? IRawMaterialLogAction.REQUESTED
        : IRawMaterialLogAction.APPROVED
    )
  });
  const { page, pageSize, search, status } = query;

  const navigate = useNavigate();
  const { handleRawMaterialClick, rawMaterials } = useRawMaterials({
    activeRawMaterialStatuses
  });

  const filteredRawMaterials = useMemo(
    () =>
      rawMaterials
        .map(value => ({
          ...value,
          title: getEnglishValue(value.title)
        }))
        .filter(
          ({ latestLogAction, title }) =>
            title?.toLowerCase().includes(search.toLowerCase()) &&
            (status === 'ALL' || latestLogAction === status)
        ),
    [rawMaterials, search, status]
  );

  return (
    <Box>
      {/* Wait until backend is ready */}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box>
          <SearchField
            id="outlined-basic"
            placeholder="Search..."
            size="small"
            style={{ marginBottom: 0 }}
            value={search}
            variant="outlined"
            onChange={event => {
              setQuery({ search: event.target.value, page: 0 });
            }}
          />
          <StyledSelect
            value={query.status}
            onChange={event => {
              setQuery({
                status: event.target.value as IRawMaterialLogAction,
                page: 0
              });
            }}
          >
            <MenuItem key="ALL" value="ALL">
              ALL STATUS
            </MenuItem>
            {activeRawMaterialStatuses.map(actionStatus => (
              <MenuItem key={actionStatus} value={actionStatus}>
                {actionStatus}
              </MenuItem>
            ))}
          </StyledSelect>
        </Box>
        <Button
          variant="contained"
          onClick={() => navigate(`/raw-materials/new`)}
        >
          Add new
        </Button>
      </Box>

      <Table<IRawMaterialMultiLang<string | undefined>>
        defaultRowsPerPage={pageSize}
        defaultSorting="title"
        headCells={headCells}
        page={page}
        rows={filteredRawMaterials}
        onPageChange={page => setQuery({ page })}
        onPageSizeChange={pageSize => setQuery({ pageSize })}
        onRowClick={handleRawMaterialClick}
      >
        {row => (
          <Fragment>
            {headCells.map(({ id }) => {
              return <TableCell key={id}>{get(row, id)}</TableCell>;
            })}
          </Fragment>
        )}
      </Table>
    </Box>
  );
};

export default ManageRawMaterialsPage;
