import React, { FC, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, FormControl, Grid, TextField, Tooltip } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import { XCircleFill } from 'styles/icons';
import { HsCode, IRawMaterialMultiLang } from 'types/rawMaterials.types';
import { DeleteRow } from 'components/ImpactClaims/ActiveImpactClaim/MultiLangFields/styles';

interface IRawMaterialHsCodeFieldsProps {
  defaultValue?: HsCode[];
}

const RawMaterialHsCodeFields: FC<IRawMaterialHsCodeFieldsProps> = ({
  defaultValue = []
}) => {
  const FIELD = 'hsCodes';

  const {
    control,
    formState: { errors },
    getValues,
    register,
    setValue
  } = useFormContext<IRawMaterialMultiLang>();

  const hsCodes = useWatch({ name: FIELD, control, defaultValue }) || [];

  const deleteRow = useCallback((itemIndex: number) => {
    const listCopy = cloneDeep(getValues(FIELD) || []) as any;
    listCopy.splice(itemIndex, 1);
    setValue(FIELD, listCopy);
  }, []);

  const addRow = useCallback(() => {
    const listCopy = cloneDeep(getValues(FIELD) || []) as any;
    listCopy.push({ code: '', description: '' });
    setValue(FIELD, listCopy);
  }, []);

  return (
    <>
      <Grid container spacing={2} xs={12}>
        {hsCodes.map((_, index) => (
          <Grid item key={index} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <FormControl
                  fullWidth
                  error={!!(errors as any)[`${FIELD}.[${index}].code` as any]}
                >
                  <TextField
                    {...register(`${FIELD}.[${index}].code` as any)}
                    fullWidth
                    label={`HS Code ${index + 1}`}
                    placeholder="1801.00.00.00"
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={
                    !!(errors as any)[`${FIELD}.[${index}].description` as any]
                  }
                >
                  <TextField
                    {...register(`${FIELD}.[${index}].description` as any)}
                    fullWidth
                    label={`Description ${index + 1}`}
                    placeholder="Cocoa beans, whole or broken, raw or roasted"
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              {hsCodes.length > 0 && (
                <Grid item xs={1}>
                  <DeleteRow onClick={() => deleteRow(index)}>
                    <Tooltip title="Delete">
                      <XCircleFill size={15} />
                    </Tooltip>
                  </DeleteRow>
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Button sx={{ marginTop: 2 }} onClick={addRow}>
        Add HS code
      </Button>
    </>
  );
};

export default RawMaterialHsCodeFields;
