import { gql } from '@apollo/client';

export const RAW_MATERIAL_FRAGMENT = gql`
  fragment rawMaterialValues on AdminRawMaterial {
    id
    title {
      language
      value
    }
    hsCodes {
      code
      description
    }
    eudrMaterialType
    logs {
      id
      rawMaterialId
      userId
      action
      createdTimestamp
    }
    latestLog {
      id
      rawMaterialId
      userId
      action
      createdTimestamp
    }
    latestLogAction
  }
`;
